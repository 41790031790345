<template>
    <v-container class="my-2  text-left pt-5">
        <v-btn
            color="purple"
            dark
            medium
            @click="showRecruitmentForm = !showRecruitmentForm"
        >
            Wyślij link rejestracyjny
        </v-btn>

        <v-dialog
            v-model="showRecruitmentForm"
            max-width="1000px"
        >
            <v-card>
                <v-card-title>
                    <span>Wyślij link rejestracyjny</span>
                    <span 
                        @click="showRecruitmentForm = !showRecruitmentForm" 
                        class="ml-auto cursor-pointer"
                    >
                    X
                    </span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            label="Email"
                            name="email"
                            type="email"
                            v-model="email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()" 
                            
                        />
                        <template>
                            <v-btn-toggle
                                v-model="users"
                                class="d-flex"
                            >
                                <v-btn  
                                    small 
                                >
                                    Copywriter
                                </v-btn >
                                <v-btn 
                                    small
                                    color="blue-grey"
                                    class="ml-3  mr-3" 
                                >
                                    Korektor
                                </v-btn>
                            </v-btn-toggle>
                        </template>
                    </v-form>
                </v-card-text>
                <v-card-title >{{feedback}}</v-card-title>
                <v-card-actions class="ml-4 pb-5">
                    <v-btn
                        color="primary"
                        :disabled="$v.$invalid"
                        @click="sendRecruitmentMail"
						:loading="isLoaded"
                    >
                        Wyślij
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {errorMsg} from '@/mixins/Validation';
const { required, email } = require('vuelidate/lib/validators')

export default{
    mixins: [validationMixin, errorMsg],
    components: {
	},
	computed: {
	},
    validations: {
        email: {
            required,
            email
        },
    },
    name: 'SendMails',
    data(){
        return {
            feedback: '',
            email: '',
            users: null,
            isLoaded: false,
            showRecruitmentForm: false
        }
    },

    methods: {
        choiseDestinationUsers(number){
            let user = 'copy';

            switch (number){
                case 0:
                    user = "copy"
                    break;
                case 1:
                    user = "korektor"
                    break;
                default:
                    user = "copy"
                    break;
            }
            return user;
        },
        sendRecruitmentMail(){
            this.isLoaded = true;
            this.$axios.post(`${this.url}api/users/send-recruitment`, {
                email: this.email,
                users:  this.choiseDestinationUsers(this.users),
                
            }).then((el) => {
                if(el.data == 'ok'){
                    this.showRecruitmentForm = false;
                    this.email = '';
                    this.users = '';
                    this.isLoaded = false;
                }else {
                    this.isLoaded = false;
                    this.feedback = el.data;
                }
            })
        }


    },

    created(){
        // this.socket.on('newClientAdded', () => {
        // });
    }
}
</script>

<style lang="scss">
.cursor-pointer{
    cursor: pointer;
}
.editr{
    display: none !important;
}
</style>
