<template>
    <v-container>
        <AddEmployee />
        <SendMails isForEmployes />
        <RecruitmentForm />
        <EmployeesTable />
    </v-container>
</template>

<script>
import AddEmployee from "@/components/molecules/AddEmployee.vue";
import EmployeesTable from "@/components/templates/EmployeesTable.vue";
import SendMails from "@/components/molecules/SendMails.vue";
import RecruitmentForm from "@/components/molecules/RecruitmentForm.vue";

export default {
    components: {
        AddEmployee,
        EmployeesTable,
        SendMails,
        RecruitmentForm,
    },
};
</script>
