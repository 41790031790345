<template>
    <div>
        <v-text-field
            @input="setEmployeesSearch"
            label="Filtruj"
            :value="getEmployeesSearch"
            single-line
            hide-details
            class="mb-3"
        ></v-text-field>

        <v-data-table
            :loading="!isDownloaded"
            :search="getEmployeesSearch"
            :headers="headers"
            item-key="id"
            :items="this.employees"
            class="elevation-2"
            :hide-default-footer="true"
            :disable-pagination="true"
            :sort-desc="false"
        >
            <template v-slot:item.pesel="{ item }">
                <v-btn
                    type="button"
                    small
                    color="indigo"
                    dark
                    text
                    v-clipboard:copy="item.pesel"
                >
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                {{ item.pesel }}
            </template>
            <template v-slot:item.rate="{ item }">
                {{ getRateById(item._id) }}
            </template>
            <template v-slot:item.email="{ item }">
                <v-btn
                    type="button"
                    small
                    color="indigo"
                    dark
                    text
                    v-clipboard:copy="item.email"
                >
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                {{ item.email }}
            </template>
            <template v-slot:item.accountNumber="{ item }">
                <v-btn
                    type="button"
                    small
                    color="indigo"
                    dark
                    text
                    v-clipboard:copy="item.accountNumber"
                >
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                {{ item.accountNumber }}
            </template>

            <template v-slot:item.date="{ item }">
                {{ item.date ? item.date.substr(0, 10) : "" }}
            </template>

            <template
                v-slot:item.actions="{ item }"
                v-if="role == 'administrator' || role == 'moderator'"
            >
                <div class="d-flex align-center" v-if="item._id !== odrzucone">
                    <EditEmployee :employee="item" />
                    <RemoveEmployee
                        v-if="!item.isBanned"
                        :itemRole="item.role"
                        :employeeId="item._id"
                    />
                    <RestoreUser
                        v-if="item.isBanned"
                        :itemRole="item.role"
                        :employeeId="item._id"
                    />
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import io from "socket.io-client";
import EditEmployee from "@/components/molecules/EditEmployee.vue";
import RemoveEmployee from "@/components/molecules/RemoveEmployee.vue";
import RestoreUser from "@/components/molecules/RestoreUser.vue";

export default {
    components: {
        EditEmployee,
        RemoveEmployee,
        RestoreUser,
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        isLoaded() {
            return this.employees.length == 0;
        },
        getEmployeesSearch() {
            return this.$store.getters.getEmployeeSearch;
        },
    },
    data() {
        return {
            socket: io(this.url),
            employees: [],
            ratings: [],
            isDownloaded: false,
            text: "Pomyślnie usunięto użytkownika",
            timeout: 2000,
            headers: [
                // { text: 'Typ', align: 'left', sortable: true, value: 'type', },
                { text: "Imię i nazwisko", value: "name", sortable: true },
                { text: "Dodano", value: "date", sortable: true },
                // { text: 'NIP/PESEL', value: 'pesel', sortable: true  },
                { text: "Numer telefonu", value: "phone", sortable: true },
                { text: "Email", value: "email", sortable: true },
                { text: "Ocena", value: "rate", sortable: true },
                // { text: 'Numer Rachunku', value: 'accountNumber', sortable: true },
                { text: "Rola", value: "role", sortable: true },
                { text: "Języki", value: "langs", sortable: true },
                { text: "Akcje", value: "actions", sortable: false },
            ],
        };
    },
    methods: {
        getRateById(id) {
            const rating = this.ratings.filter((item) => {
                return item.copy == id;
            });

            let rate = 0;

            rating.forEach((element) => {
                rate += element.rate;
            });

            let average = rate / rating.length;

            if (isNaN(average)) {
                return "Brak";
            }
            return average.toFixed(1);
        },
        getRating() {
            this.$axios.get(`${this.url}api/rating`).then((resp) => {
                this.ratings = resp.data;
            });
        },
        setEmployeesSearch(text) {
            this.$store.commit("updateFilterEmployeeSearch", text);
        },
        getEmployees() {
            this.$axios
                .get(`${this.url}api/users`)
                .then((resp) => {
                    this.employees = resp.data;
                    this.isDownloaded = true;
                })
                .then(() => {
                    this.employees = this.employees.sort(
                        this.dynamicSort("name")
                    );
                });
        },
        dynamicSort(property) {
            var sortOrder = 1;

            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }

            return function (a, b) {
                if (sortOrder == -1) {
                    return b[property].localeCompare(a[property]);
                } else {
                    return a[property].localeCompare(b[property]);
                }
            };
        },
    },
    created() {
        this.socket.on("employeeAdded", () => {
            this.getEmployees();
        });
        this.socket.on("employeeDeleted", () => {
            this.getEmployees();
        });
        this.socket.on("employeeUpdated", () => {
            this.getEmployees();
        });
        this.getEmployees();
        this.getRating();
    },
};
</script>
