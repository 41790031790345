var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mb-3",attrs:{"label":"Filtruj","value":_vm.getEmployeesSearch,"single-line":"","hide-details":""},on:{"input":_vm.setEmployeesSearch}}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":!_vm.isDownloaded,"search":_vm.getEmployeesSearch,"headers":_vm.headers,"item-key":"id","items":this.employees,"hide-default-footer":true,"disable-pagination":true,"sort-desc":false},scopedSlots:_vm._u([{key:"item.pesel",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.pesel),expression:"item.pesel",arg:"copy"}],attrs:{"type":"button","small":"","color":"indigo","dark":"","text":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_vm._v(" "+_vm._s(item.pesel)+" ")]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRateById(item._id))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.email),expression:"item.email",arg:"copy"}],attrs:{"type":"button","small":"","color":"indigo","dark":"","text":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.accountNumber",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.accountNumber),expression:"item.accountNumber",arg:"copy"}],attrs:{"type":"button","small":"","color":"indigo","dark":"","text":""}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_vm._v(" "+_vm._s(item.accountNumber)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date ? item.date.substr(0, 10) : "")+" ")]}},(_vm.role == 'administrator' || _vm.role == 'moderator')?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item._id !== _vm.odrzucone)?_c('div',{staticClass:"d-flex align-center"},[_c('EditEmployee',{attrs:{"employee":item}}),(!item.isBanned)?_c('RemoveEmployee',{attrs:{"itemRole":item.role,"employeeId":item._id}}):_vm._e(),(item.isBanned)?_c('RestoreUser',{attrs:{"itemRole":item.role,"employeeId":item._id}}):_vm._e()],1):_vm._e()]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }