<template>
    <div class="popup"
		v-if="itemRole != 'administrator' && employeeId != userID"
	>
        <div class="my-4">
			<v-btn
				color="green"
				class="mt-1 mb-1 mr-1 white--text"
				fab
				small
				@click.stop="restoreModal = true"
			>
				<v-icon dark>mdi-arrow-u-left-top-bold</v-icon>
			</v-btn>
        </div>
        <v-dialog
            v-model="restoreModal"
            max-width="400px"
        >
            <v-card>
                    <v-card-title>
                        Na pewno przywrócić pracownika?
                    </v-card-title>
                    <v-card-text>
						<v-btn
							color="green"
							class="white--text mr-4"
							@click.stop="restoreEmployee()"
						>
							TAK
						</v-btn>
						<v-btn
							color="gray"
							class="dark--text"
							@click.stop="restoreModal = false"
						>
							NIE
						</v-btn>
					</v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
	props: {
		employeeId : {
			type: String
		},
		itemRole: {
			type: String
		}
	},
	data() {
		return {
			restoreModal: false,
		}
	},
	computed: {
		userID(){
			return this.$store.getters.getUserID
		}
	
	},
	methods: {
		restoreEmployee(){
            this.$axios.put(`${this.url}api/users/restore/${this.employeeId}`)
                .then(
					this.restoreModal = true
				)
                .catch((error) => {
                    throw error
                })
        }
	},
}
</script>