<template>
	<div>
		<v-btn
            color="blue"
            class="mt-1 mb-1 mr-1 white--text"
            fab
            small
            @click="editEmployee()"
            v-if="employee.role != 'administrator'"
            >
                <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
		<v-dialog v-model="modal" max-width="400">
                <v-card>
                    <v-card-title>
                        Edycja użytkownika
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="name"
                            label="Imię i nazwisko"
                            clearable
							:error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-model="phone"
                            label="Numer telefonu"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Email"
                            clearable
							:error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                        ></v-text-field>
						<v-switch v-model="isActive" label="Zmiana hasła" class="my-0"></v-switch>
                        <v-text-field
							:disabled="!isActive"
                            v-model="password"
							type="password"
                            label="Nowe hasło"
                            clearable
                        ></v-text-field>
                            <v-radio-group
                                row
                                v-model="role"
                            >
                                <v-radio
                                    label="Administrator"
                                    value="administrator"
                                    v-if="userRole == 'administrator'"
                                ></v-radio>
                                <v-radio
                                    label="Copywriter"
                                    value=copywriter
                                ></v-radio>
                                <v-radio
                                    label="Moderator"
                                    value="moderator"
                                ></v-radio>
                                <v-radio
                                    label="Korektor"
                                    value="korektor"
                                ></v-radio>
                            </v-radio-group>
                        <v-combobox
                            v-model="langs"
                            :items="langsArray"
                            label="Języki"
                            multiple
                            chips
                        ></v-combobox>
                    </v-card-text>
                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="updateEmployee"
							:loading="isLoaded"
                        >
                            Edytuj
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>	
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {errorMsg} from '@/mixins/Validation';
const { required, email } = require('vuelidate/lib/validators')
  
export default {
	mixins: [validationMixin, errorMsg],
    validations: {
        name: {
            required,
		},
		email:{
			email,
			required
		},
    },
	props: {
		employee: {
			type: Object
		},
  
	},
	computed: {
		userRole(){
			return this.$store.getters.getUserRole;
		}
	},
	data() {
		return {
			isLoaded: false,
            modal: false,
			type: '',
			password: null,
            name: null,
            nip: null,
            email: null,
			accountNumber: null,
			phone: null,
            role: null,
            langs: null,
			sex : null,
			id: null,
			isActive: false,
            langsArray: ["PL", "EN", "DE", "FR", "IT", "CS", "DA,", "ES", "PT", "RU", "SK", "SV", "UK", "HU", "ZH", "HR", "NL", "NB"],
			
		}
	},
	methods: {

		updateEmployee(){
			this.isLoaded = true;
            this.$axios.put(`${this.url}api/users`, {
                id : this.id,
                type: this.type ,
                name: this.name,
                pesel: this.nip,
                email: this.email,
                accountNumber: this.accountNumber,
                role:  this.role,
                langs: this.langs,
				sex: this.sex,
				phone: this.phone,
				password: this.password
            }).then(() => {
				this.isLoaded = false
				this.isActive = false;
				this.password = null;
                this.modal = false;
            }).catch(err => {
                throw Error(err)
			});
		},
		editEmployee(){
            this.modal= true;
			this.type= this.employee.type;
            this.name= this.employee.name;
            this.nip= this.employee.pesel;
            this.email= this.employee.email;
            this.accountNumber= this.employee.accountNumber;
            this.role= this.employee.role;
            this.langs= this.employee.langs;
            this.sex = this.employee.sex;
			this.id = this.employee._id;
			this.phone = this.employee.phone
		},
	}
}
</script>